.content {
  padding: 1.5rem;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.header h1 {
}
.content p {
  margin-bottom: 2rem;
}
.btns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.btns a {
  font-family: "Source Sans Pro";
  font-weight: 500;
  border-radius: 7px;
  padding: 8px 20px;
  border: 1px solid;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  color: var(--green);
  border-color: var(--green);
}
.btns a:last-of-type {
  background: var(--green);
  color: #fff;
}
