.propertyWrap {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.backBtn {
  margin-bottom: 2rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--black);
  font-weight: 600;
}
.backBtn svg {
  transform: rotate(180deg);
  margin-right: 1rem;
  width: 12px;
}
.backBtn svg path {
  fill: var(--green);
}
.imgSec {
  position: relative;
  margin-bottom: 2.5rem;
}
.imgSec img {
  width: 100%;
  height: 100%;
  max-height: 550px;
  object-fit: cover;
  border-radius: 12px 12px 0px 0px;
}
.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
}
.propertyID {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-right: 6%;
  margin-top: 1rem;
}
.propertyID span {
  color: var(--green);
  margin-right: 1rem;
}
.propertyID p {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tagSec {
  display: flex;
  align-items: center;
  margin-right: auto;
  white-space: nowrap;
  margin-top: 1rem;
}
.tag {
  font-size: 18px;
  font-weight: 600;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}
.tag span {
  font-size: 14px;
  margin-left: 1rem;
  color: var(--green);
  background: #bbfacf;
  border-radius: 32px;
  padding: 5px 10px;
}
.shareIcon {
  margin-top: 1rem;
  width: 32px;
}
.ttlWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;
}
.ttl {
  font-size: var(--h2);
  font-weight: 600;
}
.location {
  font-size: 18px;
  text-decoration: underline;
  display: flex;
  align-items: center;
  color: var(--green);
}
.location svg {
  width: 16px;
  margin-right: 10px;
}
.amenityWrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 3rem;
}
.amenityList {
  display: flex;
}
.amenityList p {
  font-size: 14px;
}
.amenityList [class*="icon"] {
  width: 44px;
  height: 44px;
}
.amenityList [class*="amenities"] {
  font-size: 14px;
}
.priceWrap {
  display: flex;
  align-items: center;
  border-top: 1px solid #bbfacf;
  padding-top: 10px;
}
.priceWrap p {
  font-size: var(--h5);
  font-weight: 600;
  margin-right: 7rem;
}
.buyBtn {
  font-size: 16px;
}
.descriptionSec,
.benefitsSec,
.featuresSec,
.additionalSec,
.contactSec {
  margin-bottom: 3rem;
}
.subTtl {
  margin-bottom: 1.8rem;
  font-size: var(--h3);
  font-weight: 600;
}
.descriptionSec p {
  line-height: 1.8;
}

.list {
  line-height: 1.8;
  list-style-type: none;
}
.list li {
  position: relative;
  padding-left: 1.5rem;
}
.list li::before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--green);
  position: absolute;
  left: 0;
  top: 8px;
}
.features {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 0 1rem;
  line-height: 2;
  max-width: 850px;
}
.features li {
  width: fit-content;
}
.additional {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  /* max-width: 900px; */
  gap: 1rem;
}
.additional > div {
  display: flex;
}
.additional > div > span:nth-child(1) {
  font-weight: 600;
  width: 120px;
}

.contact {
  background: #f4f4f4;
  border-radius: 24px;
  padding: 1.8rem;
  max-width: 800px;
}
.personal {
  background: #fafafa;
  border-radius: 12px;
  padding: 1rem;
  grid-template-columns: 150px auto;
  display: grid;
  gap: 1rem;
  align-items: flex-start;
  gap: 0rem 3rem;
}
.photoSec {
  grid-row-start: 1;
  grid-row-end: 3;
}
.agentInfoSec {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 3rem;
}
.name {
  color: var(--green);
  font-weight: 600;
  font-size: var(--h5);
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
}
.avatar {
  width: 140px;
  height: 140px;
  object-fit: cover;
  margin-bottom: 1.5rem;
  border-radius: 50%;
  display: block;
  background: #f1f3f1;
}
.contactItem {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}
.contactItem span:nth-child(1) {
  color: rgba(33, 33, 33, 0.5);
  width: 35%;
  min-width: fit-content;
  display: inline-block;
  padding-right: 10px;
}
.contactItem span:nth-child(2) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.contactItem a {
  color: var(--green);
  text-decoration: underline;
  display: inline-block;
  width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.contactBtn {
  display: flex;
  align-items: center;
  font-weight: 400;
  margin-top: auto;
  font-weight: 600;
  font-size: 14px;
}
.contactBtn svg {
  width: 12px;
  margin-left: 1rem;
}
.contactBtn svg path {
  fill: var(--green);
}
.similarSec {
  padding-bottom: 2rem;
}
.similar {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin-bottom: 13rem;
  gap: 2rem;
}
.backBtn2 {
  font-weight: 400;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--black);
  font-weight: 600;
}
.backBtn2 svg {
  transform: rotate(180deg);
  margin-right: 1rem;
  width: 12px;
}
.backBtn2 svg path {
  fill: var(--green);
}
.similar [class*="imgSec"] {
  height: 340px;
}
.priceWrapMobile {
  display: none;
}

/* Doughnut Chart */
.statusSec {
  margin-bottom: 3rem;
}
.statusTtlSec {
  justify-content: space-between;
  margin-bottom: 1.8rem;
}
.statusTtlSec .subTtl {
  margin-bottom: 0;
}
.statusDropdownList {
  min-width: 100%;
  white-space: nowrap;
  right: 0;
}
.chartWrap,
.statusTtlSec {
  display: flex;
  align-items: center;
}
.chart {
  width: 240px;
  position: relative;
}
.chartTxt {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  font-size: 18px;
}
.chartTxt span {
  font-size: var(--h5);
  margin-right: 10px;
  font-weight: 600;
}
.statuses {
  padding-left: 2rem;
}
.statuses p {
  font-size: 18px;
  margin-bottom: 1rem;
}
.statuses p span {
  font-size: var(--h5);
  margin-right: 10px;
  font-weight: 600;
}
.note {
  margin-top: 2rem;
  font-weight: 600;
  font-size: 13px;
  font-style: italic;
}

/* Marketplace Breakdown */
.costBreakdown {
  display: grid;
  gap: 1rem;
}
.costBreakdown > div {
  display: flex;
}
.costBreakdown > div > span:nth-child(1) {
  font-weight: 600;
  width: 160px;
}

.sold {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #3cc13b;
  color: #3cc13b;
  border-radius: 12px;
  border: 1px solid #3cc13b;
  padding: 2px 8px;
  font-size: 13px;
  margin-left: 8px;
}

@media screen and (max-width: 1000px) {
  .imgSec {
    margin-bottom: 1rem;
  }
  .imgSec img {
    max-height: 400px;
  }
  .propertyID {
    font-size: 16px;
  }
  .tag {
    font-size: 16px;
  }
  .ttl {
    font-size: var(--h3);
  }
  .location {
    font-size: 14px;
  }
  .info {
    margin-bottom: 1.5rem;
  }
  .amenityList p {
    font-size: var(--caption);
  }
  .amenityList [class*="icon"] {
    width: 32px;
    height: 32px;
  }
  .amenityList [class*="amenities"] {
    font-size: var(--caption);
  }
  .buyBtn {
    font-size: 14px;
  }
  .subTtl {
    font-size: var(--h4);
    margin-bottom: 1.2rem;
  }
  .statusTtlSec {
    margin-bottom: 1.2rem;
  }
  .similar [class*="imgSec"] {
    height: 240px;
  }
  .features {
    grid-template-columns: 1fr 1fr;
  }
  .backBtn {
    margin-bottom: 1rem;
  }
  .statuses p,
  .chartTxt {
    font-size: 16px;
  }
  .statuses p span,
  .chartTxt span {
    font-size: 18px;
  }
}

@media screen and (max-width: 700px) {
  .chart {
    margin: auto;
  }
  .chartWrap {
    flex-direction: column;
    align-items: flex-start;
  }
  .statusTtlSec {
    flex-direction: row;
  }
  .statuses {
    padding-left: 0;
    padding-top: 2rem;
  }
  .contactItem {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 600px) {
  .imgSec img {
    max-height: 300px;
  }
  .propertyID {
    font-size: 14px;
  }
  .ttlWrap {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  .ttl {
    font-size: var(--h4);
    margin-top: 8px;
    margin-right: 3rem;
  }
  .priceWrap {
    display: none;
  }
  .priceWrapMobile {
    display: flex;
    margin-bottom: 2rem;
    margin-top: -2rem;
    justify-content: space-between;
  }
  .info {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
  .tagSec {
    margin-left: 0;
    margin-top: 12px;
  }
  .tag {
    font-size: 13px;
    font-weight: 600;
  }
  .tag span {
    font-size: 13px;
  }
  .location {
    font-size: 13px;
  }
  .shareIcon {
    width: 24px;
    right: 0;
    position: absolute;
    bottom: -5rem;
  }
  .amenityWrap {
    margin-bottom: 1rem;
  }
  .personal {
    display: block;
  }
  .contact {
    padding: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .ttl {
    font-size: var(--h5);
  }
  .subTtl {
    font-size: 20px;
    margin-bottom: 1rem;
  }
  .statuses p,
  .chartTxt {
    font-size: 14px;
  }
  .statuses p span,
  .chartTxt span {
    font-size: 14px;
  }
  .agentInfoSec {
    display: block;
  }
  .chart {
    width: 200px;
  }
}
