.login {
}
.login [class*="modal-dialog"] {
  max-width: 420px;
}
.login [class*="modal-body"] {
  padding: 1.8rem;
}
.ttl {
  text-align: center;
  font-weight: 600;
  font-size: var(--h5);
  margin-bottom: 2.5rem;
}
.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.input {
  width: 100%;
}
.halfInput {
  width: 48.5%;
}
.forgotPassword {
  background: transparent;
  color: var(--green);
  border: none;
  display: block;
  font-size: 12px;
  margin-top: -8px;
}
.continue {
  width: 100%;
  height: 40px;
  font-size: 14px;
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.signup {
  font-size: 14px;
  font-weight: 700;
}
.signup button {
  display: inline;
  margin-left: 6px;
  font-size: inherit;
}
.check {
  display: flex;
  margin-top: 4px;
}
.check > span {
  margin-left: 12px;
  font-size: 13px;
}
.check a {
  color: var(--green);
  text-decoration: none;
}
.check input {
  display: none;
}
.mark {
  height: 16px;
  width: 16px;
  background-color: transparent;
  transition: all 0.3s ease-in;
  border: 2px solid var(--green);
  display: block;
}
.check input:checked ~ .mark {
  background-color: var(--green);
  transform: rotate(0deg) scale(1);
  opacity: 1;
}
.check .mark::after {
  position: absolute;
  content: "";
  border-radius: 5px;
}
.check input:checked ~ .mark::after {
  transform: rotate(45deg) scale(1);
  left: 3.5px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  transition: 0s;
}
.errorMsg {
  font-size: 12px;
  color: var(--red);
  display: block;
  margin-top: 8px;
  width: 100%;
}
.errorMsg svg {
  width: 16px;
  margin-right: 4px;
}
.passwordGuide {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  font-size: 12px;
  margin-bottom: 1rem;
  width: 100%;
}
.passwordGuide p {
  display: flex;
  align-items: center;
  gap: 4px;
}
.passwordGuide p::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #d3d3d3;
}
.passwordGuide p.check::before {
  background: var(--green);
}
