div.header {
  display: flex;
  align-items: flex-start;
  border: none;
  padding: 1.5rem;
  padding-bottom: 0;
}
.tag {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 2px;
}
.header h1 {
  margin-bottom: 12px;
  font-size: 26px;
  font-weight: 700;
}
.txt {
  max-width: 350px;
  line-height: 1.4;
}
.closeBtn {
  background: #000;
  border-radius: 50%;
  padding: 2px;
  width: 20px;
  height: 20px;
}
.closeBtn path {
  fill: #fff;
}
.body {
  padding: 0 1.5rem 2rem;
}

.table {
  margin-top: 2rem;
}
.tableHd {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 12px 12px 0 0;
  background: #f6f6f6;
  font-weight: 700;
}
.tableHd p {
  padding: 14px;
}
.tableHd p:last-of-type {
  border-left: 1px solid #cfcaca;
}
.tableItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #e0e0e0;
  border-top: none;
  position: relative;
}
.tableItem p {
  padding: 14px;
}
.tableItem p:last-of-type {
  border-left: 1px solid #e0e0e0;
}
.tableItem:last-of-type {
  border-radius: 0 0 12px 12px;
}
.tableItem svg {
  position: absolute;
  right: 1rem;
  width: 18px;
  height: 18px;
  background: var(--red);
  top: 1rem;
}
.addBtn {
  display: block;
  font-size: 15px;
  margin-top: 2rem;
  width: 100px;
  height: 40px;
}
.empty {
  text-align: center;
  padding: 2rem 0 1rem;
}
.empty svg {
  width: 80%;
  margin-bottom: 2rem;
  max-width: 300px;
}
.empty p {
  font-size: 16px;
  font-weight: 600;
}
.centerBtn {
  margin-left: auto;
  margin-right: auto;
  width: 200px;
}