.logout [class*="modal-body"] {
  text-align: center;
}
.img {
  width: 50px;
  margin: 2rem 0 1rem;
}
.img path {
  fill: var(--red);
}
.txt {
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 16px;
}
.btnsec {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
  padding-top: 1rem;
}
.btnsec button {
  width: 35%;
  height: 44px;
  border-radius: 10px;
  min-width: 100px;
  box-shadow: unset !important;
  font-size: 14px;
  font-weight: 500;
}
button.btn2 {
  background: var(--red);
  color: #fff;
  border: 1px solid var(--red);
}
button.btn1 {
  background: #e5e5e5;
  color: #000;
  border: 1px solid #e5e5e5;
}
