.heroBg {
  background-image: url("../../../assets/images/heroBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--white);
  background-color: #d9d9d9;
}
.hero {
  text-align: center;
  min-height: 310px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.ttl {
  font-size: var(--h1);
  margin-bottom: 1rem;
  letter-spacing: var(--h1-space);
}
.txt {
  max-width: 820px;
  line-height: 1.8;
}

@media screen and (max-width: 800px) {
  .ttl {
    font-size: var(--h2);
  }
}


@media screen and (max-width: 600px) {
  .hero {
    min-height: 240px;
  }
  .ttl {
    font-size: var(--h3);
    font-weight: 600;
    letter-spacing: normal;
  }
}