.ttl {
  color: var(--black);
  font-size: 22px;
  font-weight: 600;
  position: relative;
  padding-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}
.ttl::before {
  content: "";
  background: var(--green);
  width: 20px;
  height: 40px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}
.container {
  display: grid;
  grid-template-columns: 2fr 300px;
  gap: 1rem;
}
.propertyList {
}
.propertyCard {
  margin-bottom: 2.5rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px solid #efefef;
}
.propertyCard [class*="imgSec"] {
  /* max-width: 240px; */
  height: 100%;
}
.propertyCard [class*="imgSec"] img {
  border-radius: 10px;
  max-height: 400px;
  height: 400px;
}
.propertyCard [class*="info"] {
  padding: 10px 1rem 10px 1.2rem;
  width: 54%;
}
.propertyCard [class*="name"] {
  font-size: 14px;
}
.propertyCard [class*="moreBtn"] {
  font-size: 13px;
}
.propertyCard [class*="amtSec"] {
  border: none;
  padding: 0;
}
button.secBtn {
  color: var(--green);
  margin-right: 12px;
  border-radius: 6px;
  padding: 8px 12px;
  font-weight: 600;
}
.propertyCard button.pryBtn {
  border-radius: 6px;
  padding: 8px 12px;
  font-weight: 600;
}
.emptySec {
  text-align: center;
  padding: 2rem 0;
  background: #fafafa;
  border-radius: 8px;
}
.emptySec > svg {
  width: 70%;
  max-width: 450px;
  margin-bottom: 3rem;
}
.emptySec p {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}
div.dropdown {
  display: block;
  right: 0;
}

.tabs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1.5rem;

  span {
    display: block;
    padding: 1rem;
    border-bottom: 2px solid #ece8e8;
    text-align: center;
    font-weight: 600;
    font-size: 16px;

    &.active {
      border-color: var(--green);
    }
  }
}

@media screen and (max-width: 1000px) {
  .container {
    grid-template-columns: 1fr;
    display: block;
  }
  .activity {
    /* max-width: 400px; */
  }
}

@media screen and (max-width: 800px) {
  .ttl {
    margin-top: 1.2rem;
    margin-bottom: 2rem;
    padding-left: 1.5rem;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 20px;
  }
  .ttl::before {
    width: 10px;
  }
  .activity {
    display: none;
  }
  .propertyCard {
    border: none;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 600px) {
  .propertyCard {
    max-width: 600px;
    margin-bottom: 2rem;
  }
  .propertyCard [class*="imgSec"] {
    max-width: unset;
    margin-bottom: 10px;
  }
  .propertyCard [class*="imgSec"] img {
    max-height: 250px;
  }
  .propertyCard [class*="info"] {
    padding: 10px 1rem 10px 1.2rem;
    width: 100%;
  }
  .emptySec p {
    font-size: 16px;
  }
}

@media screen and (max-width: 400px) {
  .propertyCard [class*="imgSec"] img {
    max-height: 200px;
  }
}
