.login {
}
.login [class*="modal-dialog"] {
  max-width: 400px;
}
.login [class*="modal-body"] {
  padding: 1.8rem;
}
.ttl {
  text-align: center;
  font-weight: 600;
  font-size: var(--h5);
  margin-bottom: 2.5rem;
}
.form {
}
.input {
}
.forgotPassword {
  background: transparent;
  color: var(--green);
  border: none;
  display: block;
  font-size: 12px;
  margin-top: -8px;
}
.continue {
  width: 100%;
  height: 40px;
  font-size: 14px;
  margin-top: 2rem;
}
.signup {
  font-size: 12px;
  font-weight: 700;
}
.signup button {
  display: inline;
  margin-left: 6px;
}
.outsideBtn {
  width: fit-content;
  color: var(--white);
  position: absolute;
  top: -2.5rem;
  font-weight: 600;
  font-size: 13px;
}
.outsideBtn svg {
  transform: rotate(180deg);
  width: 10px;
  margin-right: 1rem;
}
.outsideBtn svg path {
  fill: var(--green);
}
.list {
  font-size: 12px;
  color: #808080;
  line-height: 1.5;
}
.list li {
  margin-left: 1.5rem;
}
.passwordGuide {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  font-size: 12px;
  margin-bottom: 1rem;
  width: 100%;
}
.passwordGuide p {
  display: flex;
  align-items: center;
  gap: 4px;
}
.passwordGuide p::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #d3d3d3;
}
.passwordGuide p.check::before {
  background: var(--green);
}
