.welcome {
  padding-top: 5rem;
  padding-bottom: 6rem;
}
.ttl {
  color: var(--green);
  font-size: var(--h4);
  margin-bottom: 2.5rem;
  font-weight: 600;
}
.about {
  display: flex;
}
.sec1 {
  width: 380px;
}
.sec2 {
  width: calc(100% - 380px);
  padding-left: 6%;
}
.subTtl {
  font-size: var(--h4);
  font-weight: 600;
}
.moreTxt {
}
.txt {
  margin-bottom: 1rem;
  line-height: 1.8;
}
.values {
  display: flex;
  padding-bottom: 6rem;
}
.valueWrap {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 8px 1.5rem;
}
.value {
}
.icon {
  background: #25d36610;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  padding: 5px;
  margin-bottom: 1rem;
}
.icon svg {
  border: 1.3125px dashed #bfbfbf;
  border-radius: 6px;
  width: 50px;
  padding: 8px;
}
.ttl2 {
  font-weight: 600;
  font-size: var(--h5);
  margin-bottom: 1.2rem;
}
.inspirationSec {
  display: flex;
  padding-bottom: 10rem;
}
.inspirationImg {
  width: 100%;
}
.inspirationSec .txt {
  max-width: 360px;
}
.inspirationSec .sec2 {
  padding-left: 10%;
}
.bar {
  margin-bottom: 1.8rem;
}
.barTxt {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin-left: auto;
  font-size: 18px;
  margin-bottom: 14px;
}
.barTxt span {
  font-weight: 700;
}
.progressBar {
  background: #d9d9d9;
  border-radius: 100px;
  height: 15px;
  position: relative;
}
.progressBar::after {
  content: "";
  display: block;
  height: 100%;
  width: 70%;
  position: absolute;
  right: 0;
  top: 0;
  background: var(--green);
  border-radius: inherit;
}
.bar1 .barTxt,
.bar1 .progressBar::after {
  width: 70%;
}
.bar2 .barTxt,
.bar2 .progressBar::after {
  width: 80%;
}
.bar3 .barTxt,
.bar3 .progressBar::after {
  width: 85%;
}
.bar4 .barTxt,
.bar4 .progressBar::after {
  width: 90%;
}

@media screen and (max-width: 1000px) {
  .sec2 {
    padding-left: 1rem;
  }
}

@media screen and (max-width: 800px) {
  .welcome {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .values {
    padding-bottom: 3rem;
  }
  .ttl {
    font-size: var(--h5);
    margin-bottom: 1rem;
  }
  .about,
  .values,
  .inspirationSec {
    flex-direction: column;
  }
  .sec1 {
    width: 100%;
  }
  .sec2 {
    padding-left: 0;
    width: 100%;
    margin-top: 2rem;
  }
  .subTtl {
    font-size: var(--h5);
    width: 100%;
    max-width: 400px;
  }
  .inspirationSec {
    padding-bottom: 4rem;
  }
  .inspirationSec .txt {
    max-width: unset;
  }
  .inspirationSec .sec2 {
    padding: 0;
  }
  .inspirationImg {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .statSec {
    flex-direction: column-reverse;
  }
  .progressBar {
    height: 10px;
  }
  .progressBar::after {
    height: 10px;
    left: 0;
    right: unset;
  }
  .barTxt {
    font-size: 14px;
    flex-direction: row-reverse;
    margin-right: auto;
    margin-left: unset;
  }
  .statSec .inspirationImg {
    max-width: 400px;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .statSec .sec2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .valueWrap {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  .valueWrap {
    grid-template-columns: 1fr;
  }
}
