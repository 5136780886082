.sellBg {
  background: #f4f4f4;
  padding-top: 3.3rem;
  padding-bottom: 3.3rem;
}
.sell {
  text-align: center;
}
.ttl {
  font-size: var(--h3);
  font-weight: 600;
  max-width: 400px;
  margin: auto;
  margin-bottom: 2.5rem;
}
.cardWrap {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 0.9fr));
  justify-content: center;
  gap: 1.5rem;
}
.card {
  background: var(--white);
  border-radius: 12px;
  padding: 2.6rem 1rem 3.2rem;
}
.icon {
  width: 56px;
  margin-bottom: 2rem;
}
.cardTtl {
  font-size: var(--h5);
  font-weight: 600;
  margin-bottom: 12px;
}
.cardTxt {
  color: var(--neutral);
  max-width: 250px;
  margin: auto;
  line-height: 1.8;
  letter-spacing: 0.2px;
}

@media screen and (max-width: 800px) {
  .ttl {
    font-size: var(--h4);
  }
}
