.dashboardBg {
  background: var(--black);
  color: var(--white);
  padding: 4.5rem 0;
}
.dashboard {
  display: flex;
  align-items: center;
}
.txtSec {
  width: 50%;
}
.ttl {
  font-size: var(--h2);
  font-weight: 600;
  margin-bottom: 2rem;
}
.txt {
  margin-bottom: 2rem;
  opacity: 0.7;
  line-height: 1.8;
  max-width: 420px;
  font-weight: 300;
  letter-spacing: 0.3px;
}
.img {
  width: 50%;
}
.imgMobile {
  display: none;
}

@media screen and (max-width: 800px) {
  .txtSec {
    width: 100%;
  }
  .img {
    display: none;
  }
  .imgMobile {
    display: block;
    width: 80%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
  }
  .dashboard {
    text-align: center;
  }
  .ttl {
    font-size: var(--h3);
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  .txt {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
  }
}

@media screen and (max-width: 600px) {
  .ttl {
    font-size: var(--h4);
    font-weight: 500;
    max-width: 300px;
  }
  .dashboardBg {
    padding: 3rem 0;
  }
}
