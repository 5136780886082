.property {
  display: flex;
}
.column {
  flex-direction: column;
}
.imgSec {
  position: relative;
}
.tag {
  color: var(--white);
  background: var(--green);
  position: absolute;
  right: 0;
  font-size: var(--h4);
  padding: 6px 2rem;
  top: 44px;
  font-weight: 600;
}
.normal .tag {
  font-size: 18px;
  font-weight: 500;
  padding: 6px 1.2rem;
  top: 20px;
}
.row .imgSec {
  width: 53%;
  max-height: 540px;
  height: 100%;
}
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px 0px 0px 16px;
}
.column .img {
  border-radius: 10px 10px 0px 0px;
}
.info {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.row .info {
  width: 47%;
  padding-left: 2rem;
  padding-right: 1rem;
}
.row.normal .info {
  padding-left: 1.5rem;
}
.sec1 {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.sec1 p {
  color: var(--green);
  font-weight: 600;
}
.normal .sec1 {
  font-size: var(--caption);
}
.iconSec {
  width: 60px;
  display: flex;
  justify-content: space-between;
}
.normal .iconSec {
  width: 50px;
}
.normal .iconSec svg {
  width: 18px;
}
.name {
  font-size: var(--h3);
  white-space: nowrap;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 1rem;
  margin-bottom: 12px;
  text-transform: capitalize;
  max-width: 100%;
  display: flex;
  align-items: center;
}
.normal .name {
  font-size: var(--h5);
}
.row.normal .name {
  margin-bottom: 6px;
}
.address {
  color: #808080;
  margin-bottom: 16px;
}
.normal .address {
  font-size: var(--caption);
}
.row.normal .address {
  margin-bottom: 10px;
}
.amenityWrap {
  display: flex;
}
.amenities {
  font-size: var(--caption);
  width: fit-content;
  margin-right: 1.5rem;
  margin-bottom: 12px;
}
.amenities > div {
  display: flex;
  align-items: center;
}
.amenities p {
  font-size: var(--caption);
  color: #808080;
}
.amenities span {
  color: #1f1d1e;
}
.icon {
  background: #0fc94b06;
  border-radius: 50%;
  padding: 6px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 6px;
}
.normal .icon {
  width: 28px;
  margin-bottom: 2px;
}
.icon svg {
  width: 100%;
}
.description {
  margin-bottom: 1rem;
  line-height: 1.7;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.moreBtn {
  background: transparent;
  color: inherit;
  border: none;
  margin-bottom: 1rem;
  font-size: 14.5px;
  cursor: pointer;
}
.moreBtn svg {
  margin-left: 4px;
  width: 13px;
  transform: rotate(-45deg);
}
.normal .moreBtn svg {
  width: 10px;
}
.moreBtn svg path {
  fill: var(--green);
}
.imgList {
  margin-bottom: 1.5rem;
  width: 100%;
}
.row.normal .imgList {
  margin-bottom: 0.8rem;
}
.imgList p {
  color: #808080;
  margin-bottom: 12px;
}
.imgList > div {
  display: flex;
  overflow: auto;
}
.imgList img {
  width: 108px;
  min-width: 108px;
  height: 76px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 1rem;
}
.normal .imgList img {
  width: 68px;
  min-width: 68px;
  height: 50px;
  margin-right: 8px;
}
.imgList img:hover,
.activeImg {
  border: 3px solid var(--green);
  padding: 2.5px;
}
.normal .imgList img:hover,
.normal .activeImg {
  border: 2px solid var(--green);
  padding: 1.5px;
}
.amtSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #bbfacf;
  padding-top: 1rem;
  margin-top: auto;
  width: 100%;
}
.normal .amtSec {
  padding-top: 8px;
}
.amtSec p {
  font-size: var(--h5);
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}
.normal .amtSec p {
  font-size: 18px;
  font-weight: 700;
}
.amtSec button {
  border-radius: 8px;
  font-size: 16px;
  padding: 10px 24px;
}
.normal .amtSec button {
  font-size: 13.5px;
  padding: 8px 20px;
}
.amtSec div {
  display: flex;
  white-space: nowrap;
}
.amtSec button.secBtn {
  padding: 8px;
  margin-right: 8px;
}
.sold {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #3cc13b;
  color: #3cc13b;
  border-radius: 12px;
  border: 1px solid #3cc13b;
  padding: 2px 8px;
  font-size: 12px;
  margin-left: 8px;
}
@media screen and (max-width: 1000px) {
  .row .imgSec {
    width: 40%;
  }
  .row .info {
    width: 60%;
  }
}

@media screen and (max-width: 600px) {
  .row {
    flex-direction: column;
  }
  .row .img {
    border-radius: 10px 10px 0px 0px;
  }
  .row .imgSec {
    width: 100%;
    max-height: 540px;
  }
  .row .info {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .imgList {
    /* display: none; */
    width: calc(100vw - 2rem);
  }
  .tag {
    font-size: 16px;
    font-weight: 500;
    padding: 6px 1.2rem;
    top: 20px;
  }
  .sec1 {
    font-size: var(--caption);
  }
  .iconSec svg {
    width: 18px;
  }
  .name {
    font-size: var(--h5);
    white-space: normal;
  }
  .address {
    font-size: var(--caption);
  }
  .icon {
    width: 28px;
    margin-bottom: 2px;
  }
  .moreBtn {
    font-size: 13px;
  }
  .moreBtn svg {
    width: 10px;
  }
  .imgList img {
    width: 68px;
    min-width: 68px;
    height: 50px;
    margin-right: 8px;
  }
  .imgList img:hover,
  .activeImg {
    border: 2px solid var(--green);
    padding: 1.5px;
  }

  .amtSec {
    padding-top: 8px;
  }

  .amtSec p {
    font-size: 18px;
    font-weight: 700;
  }
  .amtSec button {
    font-size: 13.5px;
    padding: 8px 20px;
  }
}
