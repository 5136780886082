.ttl {
  color: var(--black);
  font-size: 22px;
  font-weight: 600;
  position: relative;
  padding-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}
.ttl::before {
  content: "";
  background: var(--green);
  width: 20px;
  height: 40px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}
.container {
  display: grid;
  grid-template-columns: 1fr 2.1fr;
  gap: 1rem;
}
.chatList {
}
.listSearch {
  height: 44px;
  border: 1px solid var(--neutral);
  border-radius: 10px;
  margin-bottom: 2rem;
  position: relative;
}
.listSearch svg {
  position: absolute;
  left: 12px;
  top: 13px;
  width: 18px;
}
.listSearch input {
  height: 100%;
  width: 100%;
  background: transparent;
  border: none;
  padding-left: 3rem;
  font-size: 14px;
}
.activeNow {
  margin-bottom: 2rem;
}
.activeNow p {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 1rem;
}
.activeNow div {
  display: flex;
  overflow: auto hidden;
  width: 100%;
  max-width: 24vw;
}
.activeNow div img {
  width: 50px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
  background: #d0e3ff;
}
.chats {
  height: 100%;
  max-height: calc(100vh - 220px);
  overflow-y: scroll;
}
.chatItem {
  padding: 10px 0;
  border-bottom: 1px solid #efefef;
}
.chat {
  display: flex;
  border-radius: 12px;
  padding: 12px;
}
.activeChat {
  background: rgba(191, 191, 191, 0.15);
}
.chat::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--green);
  margin-top: 3px;
}
.chat img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
  background: #d0e3ff;
  margin-right: 12px;
}
.chat div {
  margin-top: auto;
  margin-bottom: auto;
}
.chat div p:first-of-type {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 6px;
}
.chat div p:last-of-type {
  font-size: 15px;
  color: #6f767e;
  font-weight: 500;
}
.chat > p {
  margin-left: auto;
  font-size: 12px;
  color: #9a9fa5;
  margin-right: 1rem;
  font-weight: 600;
}
.chatAreaWrap {
  position: relative;
}
.chatArea {
  border-radius: 24px 24px 0px 0px;
  width: 100%;
}
.chatHd {
  display: flex;
  align-items: center;
  padding: 1rem 1.6rem;
  border-bottom: 1px solid rgba(191, 191, 191, 0.15);
  background: rgba(191, 191, 191, 0.1);
  border-radius: 24px 24px 0px 0px;
}
.chatImg {
  width: 40px;
  margin-right: 1rem;
  border-radius: 50%;
  object-position: center;
  object-fit: contain;
}
.chatTtl {
  font-weight: 600;
  font-size: 18px;
}
.chatSearch {
  margin-left: auto;
  width: 20px;
}
.chatMenu {
  margin-left: 1.5rem;
  width: 26px;
}
.chatBody {
  padding: 2rem 1.6rem;
  max-height: calc(100vh - 200px);
  height: 100%;
  overflow: auto;
  background: rgba(191, 191, 191, 0.1);
}
.chatBody::-webkit-scrollbar,
.chats::-webkit-scrollbar,
.chatInputArea textarea::-webkit-scrollbar {
  width: 0px;
}
.msgItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}
.msgAvatar {
  width: 40px;
  margin-right: 1rem;
  border-radius: 50%;
  object-position: center;
  object-fit: contain;
}
.msgDetail {
}
.msgDetail div {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.msgName {
  font-weight: 600;
}
.msgTime {
  color: #bfbfbf;
  font-size: 12px;
  margin-left: 6rem;
}
.msgTxt {
  background: rgba(158, 190, 160, 0.1);
  border-radius: 0px 12px 12px 12px;
  padding: 10px;
  max-width: 280px;
  font-size: 13px;
  line-height: 1.5;
}
.reverse {
  flex-direction: row-reverse;
}
.reverse .msgTxt {
  border-radius: 12px 0px 12px 12px;
}
.reverse .msgDetail div {
  flex-direction: row-reverse;
}
.reverse .msgAvatar {
  margin-left: 1rem;
  margin-right: 0;
}
.reverse .msgTime {
  margin-right: 6rem;
  margin-left: 0;
}
.chatInputArea {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 1.2rem;
}
.chatInputArea textarea {
  width: calc(100% - 120px);
  background: rgba(191, 191, 191, 0.2);
  border-radius: 24px;
  border: none;
  font-size: 14px;
  padding: 12px;
  height: 44px;
  min-height: 44px;
}

.chatAreaReduce {
  width: calc(100% - 230px);
}
.searchChat {
  background: #1f1d1e;
  border-radius: 16px 0px 0px 16px;
  width: 250px;
  position: absolute;
  right: -3rem;
  height: 100%;
  padding: 2rem 1rem;
  color: #fafafa;
  display: flex;
  flex-direction: column;
  top: 0;
}
.searchChatHd {
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
}
.searchChatHd svg {
}
.searchChatHd svg path {
  fill: #808080;
}
.searchChatHd p {
  width: 100%;
  text-align: center;
}
.searchChatResults {
  background: rgba(191, 191, 191, 0.1);
  border-radius: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.searchChat .listSearch {
  min-height: 44px;
  margin-bottom: 1.5rem;
}
.searchChatResultsDefault {
  margin: auto;
  font-size: 12px;
  color: rgba(128, 128, 128, 0.8);
}
.backBtn {
  display: none;
}
@media screen and (max-width: 1000px) {
  .container {
    grid-template-columns: 1fr;
  }
  .activeNow {
    border-bottom: 1px solid #efefef;
    padding-bottom: 1rem;
    margin-bottom: 0;
  }
  .activeNow div {
    max-width: calc(100vw - 2rem);
  }
  .chats {
    max-height: unset;
    height: unset;
  }
  .backBtn {
    display: block;
    width: 12px;
    transform: rotate(180deg);
    margin-right: 1rem;
  }
}

@media screen and (max-width: 800px) {
  .container {
    width: 100vw;
    margin-left: -1rem;
  }
  .ttl {
    margin-top: 1.2rem;
    margin-bottom: 2rem;
    padding-left: 1.5rem;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 20px;
  }
  .ttl::before {
    width: 10px;
  }
  .chatInputArea {
    position: fixed;
    bottom: 0;
    width: 100vw;
    left: 0;
    background: var(--white);
  }
  .chatInputArea textarea {
    height: 44px;
    resize: none;
    width: calc(100% - 100px);
  }
  .chatInputArea svg {
    width: 20px;
  }
  .chatHd {
    border-radius: 0;
    background: var(--white);
    padding: 12px 1rem;
  }
  .chatTtl {
    font-size: 16px;
  }
  .chatBody {
    padding: 1rem;
  }
  .searchChat {
    width: 300px;
    right: 0;
    height: calc(100vh - 63px);
    top: -4px;
    border-radius: 0;
  }
  .chatSearch {
    width: 16px;
  }
  .chatMenu {
    width: 20px;
    margin-left: 1rem;
  }
  .chatList {
    padding: 0 1rem;
  }
  .chatAreaReduce {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .chat div p:first-of-type {
    font-size: 15px;
    font-weight: 600;
  }
  .chat div p:last-of-type {
    font-size: 14px;
  }
}