.topDeals {
  padding-top: 4rem;
  padding-bottom: 8rem;
}
.ttl {
  font-size: var(--h3);
  margin-bottom: 2.3rem;
}
.propertyList {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  width: calc(100% + (50vw + 3rem - 603px));
}
.propertyList > div {
  min-width: 83%;
  margin-right: 4rem;
}

.listings {
  padding-top: 3rem;
  padding-bottom: 5rem;
}
.searchAndFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.searchWrap {
  position: relative;
}
.searchWrap svg {
  position: absolute;
  width: 20px;
  left: 16px;
  top: 15px;
}
.searchWrap input {
  height: 50px;
  background: transparent;
  border: 1px solid #808080;
  border-radius: 12px;
  min-width: 280px;
  padding-left: 3rem;
  padding-right: 1rem;
  font-size: 15px;
}
.filterWrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.filterItem {
  background: transparent;
  border: none;
  color: inherit;
  font-size: var(--base);
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  margin-right: 1.5rem;
  position: relative;
}
.filterItem--active {
  display: block;
  width: 6px;
  height: 6px;
  background: var(--green);
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 0;
}
.filterItem svg {
  width: 8px;
  transform: rotate(90deg);
  margin-left: 10px;
}
.resetBtn {
  color: #808080;
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: 600;
}

.propertyListings {
  padding-top: 3rem;
  display: grid;
  gap: 3rem 2rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.propertyListings [class*="img"] {
  max-height: 200px;
}
.empty {
  text-align: center;
}
.empty svg {
  width: 80%;
  max-width: 400px;
  margin-top: 4rem;
  margin-bottom: 2rem;
}
.empty p {
  font-size: 16px;
  font-weight: 500;
}
.scheduleCallBtn {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: black;
}
a.scheduleCallBtn {
  font-size: 14px;
  margin-right: 10px;
  font-weight: 600;
}
.scheduleCallBtn svg {
  margin-right: 4px;
  width: 16px;
}
.scheduleCallBtn svg path {
  fill: #000;
}

@media screen and (max-width: 1000px) {
  .searchAndFilter {
    flex-direction: column;
    align-items: flex-start;
  }
  .filterWrap {
    margin-top: 1rem;
  }
  .propertyList {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .searchWrap {
    width: 100%;
  }
  .searchWrap input {
    height: 40px;
    border-radius: 7px;
    font-size: 14px;
    padding-left: 2.4rem;
    width: 100%;
  }
  .searchWrap svg {
    width: 14px;
    left: 12px;
    top: 14px;
  }
  .ttl {
    font-size: var(--h4);
    margin-bottom: 1.5rem;
    font-weight: 600;
  }
  .topDeals {
    padding-bottom: 2rem;
    padding-top: 2rem;
    border-bottom: 1px solid #80808080;
  }
  .propertyListings {
    padding-top: 10px;
  }
  .listings {
    padding-top: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .propertyList {
    width: 100%;
  }
  .propertyList > div {
    min-width: 70%;
    margin-right: 1rem;
    min-width: 320px;
  }
  .dealCard [class*="imgList"] {
    width: 100%;
  }
  .propertyList > div [class*="imgSec"] img {
    max-height: 250px;
  }
  .filterWrap {
    overflow: auto;
    width: 100%;
    justify-content: flex-start;
  }
}
