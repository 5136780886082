.heroBg {
  background-image: url("../../../assets/images/heroBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  color: var(--white);
  background-color: #d9d9d9;
}
.hero {
  padding-top: 8rem;
  padding-bottom: 14rem;
}
.label {
  font-size: var(--h5);
  color: var(--green);
  font-weight: 600;
  margin-bottom: 14px;
}
.ttl {
  font-size: var(--h1);
  max-width: 600px;
  font-weight: 700;
  margin-bottom: 10px;
  letter-spacing: var(--h1-space);
  max-width: 650px;
}
.txt {
  letter-spacing: 0.3px;
  max-width: 450px;
  line-height: 1.5;
  margin-bottom: 1.6rem;
}
button.btn {
  padding: 12.5px 44px;
  font-size: 12.3px;
  letter-spacing: 0.3px;
  font-weight: 500;
}

@media screen and (max-width: 800px) {
  .hero {
    padding-top: 8rem;
    padding-bottom: 10rem;
  }
  .label {
    font-size: 16px;
  }
  .ttl {
    font-size: var(--h2);
    letter-spacing: normal;
    max-width: 500px;
  }
  .txt {
    max-width: 400px;
  }
}

@media screen and (max-width: 600px) {
  .heroBg {
    background-position-x: left;
  }
  .hero {
    padding-top: 6rem;
    padding-bottom: 8rem;
  }
  .label {
    font-size: 14px;
    margin-bottom: 12px;
    font-size: 12px;
    letter-spacing: 0.3px;
  }
  .ttl {
    font-size: var(--h4);
    letter-spacing: normal;
    max-width: 350px;
  }
  .txt {
    max-width: 300px;
    font-size: 14px;
  }
}

