.dropdownIcon {
  width: 22px;
  margin-right: 12px;
}
.id {
  display: block;
  max-width: 100px;
  margin: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.success {
  color: var(--green);
}
.pending {
  color: var(--bs-warning);
}
.failed {
  color: var(--bs-red);
}