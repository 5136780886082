.dialog {
  z-index: 1501;
}
.modal {
  padding: 0;
  max-width: 500px;
  border-radius: 8px;
}

.header {
  border-bottom: 1px solid #efefef;
  display: flex;
  justify-content: space-between;
  padding: 1.2rem 14px;
}
.header p {
  color: var(--text-main);
  font-weight: 600;
  font-size: 16px;
}
.txt {
  margin-bottom: 2rem;
  padding: 14px;
  margin-top: 6px;
}

.footer {
  padding: 1.2rem 14px;
  display: flex;
  justify-content: flex-end;
}
.footer button {
  margin-left: 1rem;
  border-radius: 8px;
  padding: 12px 2rem;
  font-weight: 600;
}

.footer button[class*="fill"] {
  font-weight: 400;
}
