.dropdownIcon {
  width: 22px;
  margin-right: 12px;
}
.dropdownIcon path {
  fill: #000;
}
.status {
  border-radius: 4px;
  border: 1px solid;
  padding: 6px 4px;
  width: fit-content;
  margin: auto;
  width: 90px;
  text-align: center;
  font-weight: 500
}
.pending {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #f3aa1c;
  color: #f3aa1c;
}
.rejected {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #f03738;
  color: #f03738;
}
.incomplete {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #c2c9d1;
  color: #c2c9d1;
}
.approved {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #3cc13b;
  color: #3cc13b;
}
.sold {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #3cc13b;
  color: #3cc13b;
  border-radius: 12px;
  border: 1px solid #3cc13b;
  padding: 2px 8px;
  font-size: 12px;
  margin-left: 4px;
}
.tableBody td {
  vertical-align: middle;
  padding-top: 8px;
  padding-bottom: 8px;
}
.name {
  text-overflow: ellipsis;
  max-width: calc(40 / 100 * (100vw - 350px));
  overflow: hidden;
  white-space: nowrap;
  margin: auto;
  min-width: 180px;
}
.propertyID {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: auto;
  max-width: 100px;
}