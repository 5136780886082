.ttl {
  color: var(--black);
  font-size: 22px;
  font-weight: 600;
  position: relative;
  padding-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.ttl::before {
  content: "";
  background: var(--green);
  width: 20px;
  height: 40px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}
.accountList {
  display: flex;
  overflow: scroll hidden;
}
.accountCard {
  border: 2px solid var(--green);
  border-radius: 8px;
  padding: 1rem;
  width: fit-content;
  display: flex;
  align-items: flex-start;
  min-width: 330px;
  margin-right: 1.5rem;
  position: relative;
}
.accountCard input {
  appearance: none;
  -webkit-appearance: none;
  border-radius: 50%;
  background: #ccc;
  border: 3px solid #f2f4f2;
  box-shadow: 0 0 0 1px #ccc;
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-top: 4px;
}
.accountCard .selectedRadio {
  background: var(--green);
  box-shadow: 0 0 0 1px var(--green);
  border: 3px solid #fff;
}
.accountInfo {
  margin-left: 1rem;
}
.accountCard .delete {
  position: absolute;
  right: 1rem;
  top: 1rem;
  display: none;
}
.accountCard:hover .delete {
  display: block;
}
.name {
  color: var(--neutral);
  font-size: 18px;
  margin-bottom: 12px;
}
.bank {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 8px;
}
.number {
  font-size: 14px;
}
.note {
  margin-top: 10px;
  font-size: 12px;
  display: block;
}
.addBtn {
  font-size: 14px;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  height: 44px;
  font-family: "Source Sans Pro";
  font-weight: 500;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.addBtn svg {
  margin-right: 12px;
}
.tableWrap {
}
.emptySec {
  text-align: center;
  padding: 2rem 0;
  background: #fafafa;
  border-radius: 8px;
}
.emptySec > svg {
  width: 70%;
  max-width: 250px;
  margin-bottom: 3rem;
}
.emptySec p {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}
.tableTtl {
  font-size: var(--h5);
  font-weight: 600;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1200px) {
  .tableWrap {
    padding: 1px 0px;
    overflow-x: scroll;
  }
  .tableWrap table {
    min-width: 600px;
  }
  .tableTtl {
    font-size: 20px;
  }
}
@media screen and (max-width: 800px) {
  .name {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .bank {
    font-size: 18px;
  }
  .number {
    font-size: 13px;
  }
  .accountCard {
    min-width: 300px;
    margin-right: 1rem;
    padding: 12px;
  }
  .accountCard input {
    width: 14px;
    height: 14px;
    margin-top: 4px;
  }
  .tableTtl {
    font-size: 18px;
    margin-bottom: 1.5rem;
  }
  .addBtn {
    font-size: 14px;
    margin-top: 1.5rem;
  }
}
