/* Activity */
.activityTtl {
  font-size: var(--h5);
  font-weight: 600;
  margin-bottom: 2rem;
}
.activityList {
  /* padding-left: 2rem; */
}
.activity::before {
  content: "";
  display: block;
  width: 14px;
  height: 14px;
  border: 2px solid;
  border-radius: 50%;
  position: absolute;
  left: -28px;
  top: 24px;
}
.activity {
  margin-bottom: 12px;
  border: 0.85px solid #eef3ef;
  border-radius: 8px;
  padding: 10px 12px;
  position: relative;
  margin-left: 32px;
  /* overflow: hidden; */
}
.activityTxt1 {
  color: #808080;
  margin-bottom: 6px;
}
.activityTxt2 {
  font-weight: 700;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.activity:hover .activityTxt2 {
  white-space: normal;
}
.activityClose {
  position: absolute;
  width: 22px;
  top: 6px;
  right: 14px;
}
.activity:nth-of-type(3n + 1) {
  background: #e1f5e7;
}
.activity:nth-of-type(3n + 1)::before {
  background: rgba(15, 201, 75, 0.1);
  box-shadow: 0 0 0 4px rgb(15 201 75 / 10%);
  border-color: var(--green);
}
.activity:nth-of-type(3n + 2) {
  background: #f4e2f4;
}
.activity:nth-of-type(3n + 2)::before {
  border-color: #c50fc9;
  background: rgba(197, 15, 201, 0.1);
  box-shadow: 0 0 0 4px rgb(197 15 201 /10%);
}
.activity:nth-of-type(3n + 3) {
  background: #e3e6f7;
}
.activity:nth-of-type(3n + 3)::before {
  border-color: #1231d9;
  background: rgba(18, 49, 217, 0.1);
  box-shadow: 0 0 0 4px rgb(18 49 217 /10%);
}
.empty {
  text-align: center;
}
.empty svg {
  width: 70%;
  max-width: 300px;
  margin-bottom: 1rem;
}
@media screen and (max-width: 800px) {
  .activityTtl {
    margin-bottom: 1rem;
    font-size: 18px;
  }
}
