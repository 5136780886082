div.header {
  display: flex;
  align-items: flex-start;
  border: none;
  padding: 1.5rem;
  padding-bottom: 0;
}
.tag {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 2px;
}
.header h1 {
  margin-bottom: 12px;
  font-size: 26px;
  font-weight: 700;
}
.txt {
  color: #8d8d8d;
  max-width: 350px;
  line-height: 1.4;
}
.closeBtn {
  background: #000;
  border-radius: 50%;
  padding: 2px;
  width: 20px;
  height: 20px;
}
.closeBtn path {
  fill: #fff;
}
div.body {
  padding: 1.5rem 1.5rem;
}
.fieldset {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 5%;
}
.input {
  margin-bottom: 1.5rem;
}
.submitBtn {
  margin-top: 1rem;
  width: 100%;
  height: 44px;
  font-size: 16px;
}
