.ttl {
  color: var(--black);
  font-size: 22px;
  font-weight: 600;
  position: relative;
  padding-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}
.ttl::before {
  content: "";
  background: var(--green);
  width: 20px;
  height: 40px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}
.nav {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.nav span {
  margin-right: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem 0;
}
.nav span svg {
  margin-right: 14px;
}
.activeNav {
  border-bottom: 1px solid var(--green);
}
.formWrap {
  margin-top: 2rem;
}
.accountForm {
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.accountForm .inputWrap {
  width: 48%;
  margin-bottom: 2rem;
}
.securityForm {
  max-width: 400px;
}
.securityForm .inputWrap {
  margin-bottom: 2rem;
}
.inputWrap label {
  font-size: 13px;
}
.inputWrap input {
  border-color: var(--neutral);
  background: transparent;
}
.btnWrap {
  width: 100%;
  padding-top: 2rem;
}
.btn {
  font-size: 17px;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  height: 44px;
  font-family: "Source Sans Pro";
  font-weight: 500;
}

.accountInfo {
  margin-bottom: 4rem;
}

.accountInfoTtl {
  margin-bottom: 1.5rem;
  font-size: 24px;
  font-weight: 600;
}

.personalForm {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 0 1rem;
  max-width: 700px;
}

.imageSec {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}
.imageWrap {
  border: 1px solid #808080;
  background: var(--black);
  border-radius: 50%;
  width: 100px;
  min-width: 100px;
  height: 100px;
  position: relative;
  margin-right: 1.2rem;
}
.imageWrap img {
  border-radius: inherit;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image {
  background: var(--black);
  border-radius: 50%;
  padding: 4px;
  border: 1px solid #fcfcfc;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
}
.image svg {
}
.logoTtl {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.btnSec {
  display: flex;
  gap: 1rem;
  padding-top: 1rem;
}

.btnSec button {
  font-size: 14px;
  width: 100px;
  height: 40px;
}
.btnSec button:first-of-type {
  color: var(--red);
  border-color: var(--red);
}
.txtArea {
  grid-column-start: 1;
  grid-column-end: 3;
}
.passwordGuide {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  font-size: 12px;
  margin-bottom: 2rem;
  width: 100%;
}
.passwordGuide p {
  display: flex;
  align-items: center;
  gap: 4px;
}
.passwordGuide p::before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #d3d3d3;
}
.passwordGuide p.check::before {
  background: var(--green);
}

@media screen and (max-width: 800px) {
  .ttl {
    margin-top: 1.2rem;
    margin-bottom: 1rem;
    padding-left: 1.5rem;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 20px;
  }
  .ttl::before {
    width: 10px;
  }
  .nav {
    font-size: 14px;
    border: none;
  }
  .nav span {
    padding: 8px 12px;
    margin-right: 1.5rem;
  }
  .activeNav {
    border-width: 2px;
  }
  .nav span:first-of-type.activeNav svg path {
    stroke: var(--green);
  }
  .nav span:last-of-type.activeNav.activeNav svg path {
    fill: var(--green);
  }
  .nav span svg {
    margin-right: 12px;
  }
}

@media screen and (max-width: 600px) {
  .accountForm .inputWrap,
  .securityForm .inputWrap {
    width: 100%;
    margin-bottom: 1rem;
  }
  .accountForm,
  .securityForm {
    max-width: unset;
  }
  .btn {
    width: 100%;
    justify-content: center;
    font-size: 16px;
  }
  .accountInfoTtl {
    font-size: 20px;
  }
  .imageWrap {
    min-width: 80px;
    width: 80px;
    height: 80px;
    margin-right: 1rem;
  }
  .logoTtl {
    font-size: 18px;
    margin-bottom: 6px;
  }
  .logoTxt {
    font-size: 13px;
  }
  .txtArea {
    grid-column-start: 1;
    grid-column-end: 2;
  }
}
