.login {
}
.login [class*="modal-dialog"] {
  max-width: 400px;
}
.login [class*="modal-body"] {
  padding: 1.8rem;
}
.ttl {
  text-align: center;
  font-weight: 600;
  font-size: var(--h5);
  margin-bottom: 2.5rem;
}
.form {
}
.input {
}
.forgotPassword {
  background: transparent;
  color: var(--green);
  border: none;
  display: block;
  font-size: 13px;
  margin-top: -8px;
}
.continue {
  width: 100%;
  height: 40px;
  font-size: 14px;
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.signup {
  font-size: 14px;
  font-weight: 700;
}
.signup button {
  display: inline;
  margin-left: 6px;
  font-size: inherit;
}
