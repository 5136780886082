.dialog {
  max-width: 600px !important;
}
.content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  font-size: 14px;
  height: 90vh;
  line-height: 1.5;
}
.side {
  background: #000;
  padding: 3rem 1rem 2rem;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  color: #fff;
  display: flex;
  flex-direction: column;
}
.logo {
  margin-bottom: 3rem;
}
.logo svg {
  width: 28px;
}
.logo p {
  font-size: 22px;
  line-height: 0.8;
  margin-left: 10px;
}
.body {
  background: #fff;
  color: #000;
  padding: 2rem 1rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  flex-direction: column;
}
.invoiceNo {
  color: var(--green);
  margin-bottom: 3rem;
}
.contact {
  margin-bottom: 3rem;
}
.contact p:first-of-type {
  color: var(--green);
}
.support {
  margin-top: auto;
  font-size: 13px;
}
.header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.8rem;
}
.ttl {
  font-size: 44px;
  font-weight: 600;
}
.status {
  padding: 2px 18px;
  border-radius: 16px;
  text-transform: capitalize;
}
.status--paid {
  color: var(--green);
  background: var(--green-bg);
}
.status--pending {
  color: var(--bs-warning);
  background: #ffc1071a;
}
.property {
  margin-bottom: 3rem;
}
.propertyId {
  font-weight: 600;
}
.address {
}
.tableHd {
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 1rem;
}
.tableItem {
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  margin-bottom: 1rem;
}
.tableItem p:last-of-type {
  color: var(--green);
}
.tableTotal {
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--black);
  font-weight: 600;
  margin-bottom: 4rem;
  align-items: center;
}
.tableTotal p:last-of-type {
  color: var(--green);
  font-size: 20px;
}

.body .bttomLogo {
  margin-top: auto;
  margin-left: auto;
  font-weight: 800;
}

.noReceipt {
  padding: 6rem 2rem;
  font-weight: 600;
  text-align: center;
}
