.contact {
  padding-top: 5rem;
  padding-bottom: 10rem;
}
.hero {
  padding-bottom: 3rem;
}
.ttl {
  font-size: var(--h2);
  font-weight: 600;
  margin-bottom: 1rem;
}
.ttl span {
  color: var(--green);
}
.txt {
  max-width: 600px;
  line-height: 1.8;
}

.contactWrap {
  display: flex;
  justify-content: space-between;
}
.contactWrap label {
  font-weight: 700;
  color: var(--black);
  font-size: 14px;
}
.form {
  background: #f4f4f4;
  border-radius: 16px;
  padding: 1.8rem;
  max-width: 600px;
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.input {
  width: 47.5%;
}
.textarea {
  width: 100%;
}
.input input, .textarea textarea, .input [class*="formSelect__control"] {
  border-color: rgba(128, 128, 128, 0.6);;
}
.contactInfo {
  padding-left: 2rem;
}
.contactItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2.3rem;
}
.contactItem:first-of-type svg {
  width: 26px;
}
.contactItem svg {
  margin-right: 1.5rem;
  width: 20px;
}
.contactTxtSec {
}
.contactTtl {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 8px;
}
.contactTxt {
  margin-bottom: 12px;
}
.contactTxt2 {
  color: var(--green);
  text-decoration: none;
  font-weight: 600;
}

@media screen and (max-width: 900px) {
  .contactWrap {
    flex-direction: column;
  }
  .contactInfo {
    margin-top: 3rem;
    padding-left: 0;
  }
  .form {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .ttl {
    font-size: var(--h3);
  }
  .contact {
    padding-bottom: 5rem;
  }
}

@media screen and (max-width: 600px) {
  .ttl {
    font-size: var(--h4);
  }
  .input {
    width: 100%;
  }
}
