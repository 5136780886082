.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.inputBox {
  border: 1px solid #1f1d1e;
  padding: 6px 10px;
  border-radius: 6px;
  width: 72px;
  height: 46px;
  display: flex;
}

.input {
  width: 100%;
  background: transparent;
  border: none;
  font-size: 15px;
  font-weight: 500;
}

/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type="number"] {
  -moz-appearance: textfield;
}

.inputBox span {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin-left: 4px;
}
.inputBox span svg {
  width: 18px;
  padding: 4px;
}
.inputBox span svg path {
  fill: var(--black);
}
.down {
  transform: rotate(180deg);
}
.disabled {
  cursor: not-allowed !important;
  opacity: 0.6;
}
