.container {
  display: flex;
  justify-content: space-between;
}
.main {
  position: fixed;
  width: calc(100% - 500px - 8rem);
  left: calc(220px + 3rem);
  overflow-y: auto;
  height: calc(100vh - 77px);
  padding-bottom: 2rem;
  padding-top: 2rem;
  top: 77px;
}
.extra {
  position: fixed;
  left: calc((100% - 500px - 7rem) + 220px + 4rem);
  width: 300px;
  overflow-y: auto;
  height: calc(100vh - 77px);
  padding-bottom: 2rem;
  padding-top: 2rem;
  top: 77px;
  padding-right: 1rem;
}
.main::-webkit-scrollbar,
.extra::-webkit-scrollbar {
  width: 0;
}
.secWrap {
  background: #f4f4f4;
  border-radius: 12px;
  padding: 1rem 1.2rem;
  margin-bottom: 3rem;
}
.secWrap > div {
  background: #fafafa;
  border-radius: 8px;
  padding: 1rem;
}
.statList {
  --auto-grid-min-size: 20%;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  grid-gap: 1rem;
}
.statCard {
  background: #fafafa;
  box-shadow: 289px 720px 310px rgba(39, 181, 67, 0.01),
    163px 405px 262px rgba(39, 181, 67, 0.03),
    72px 180px 194px rgba(39, 181, 67, 0.04),
    18px 45px 107px rgba(39, 181, 67, 0.05), 0px 0px 0px rgba(39, 181, 67, 0.05);
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.statIcon {
  margin-right: 8px;
  width: 14px;
  margin-top: 3px;
}
.statInfo {
}
.statTxt1 {
  margin-bottom: 8px;
}
.statTxt2 {
  font-weight: 600;
  margin-bottom: 8px;
}
.statTxt3 {
}
.statTxt3 span {
  font-weight: 600;
}
.statTxt3 svg {
  margin-left: 10px;
  margin-top: -6px;
  width: 8px;
}
.downwardRed {
  transform: rotate(180deg);
  margin-top: 2px;
}
.downwardRed path {
  fill: #fa6564;
}
.statRange {
  justify-content: flex-end;
}
.trendsWrap > div {
  padding: 0;
}
.trendsHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ebf1ec;
  padding: 1.6rem 1.6rem 12px;
}
.trendsHeading p {
  font-weight: 600;
  font-size: 22px;
}
.trendsHeading input {
  /* The native appearance is hidden */
  appearance: none;
  -webkit-appearance: none;

  /* For a circular appearance we need a border-radius. */
  border-radius: 50%;

  /* The background will be the radio dot's color. */
  background: #ccc;

  /* The border will be the spacing between the dot and the outer circle */
  border: 3px solid #f2f4f2;

  /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
  box-shadow: 0 0 0 1px #ccc;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
input[type="radio"].selectedRadio {
  background: var(--green);
  box-shadow: 0 0 0 1px var(--green);
  border: 3px solid #fff;
}
.trendsHeading fieldset {
  display: flex;
  align-items: center;
}
.trendsHeading fieldset label {
  display: flex;
  align-items: center;
  margin-right: 1rem;
}
.trendsHeading fieldset label span {
  font-weight: 600;
  margin-left: 8px;
}
.dropdown {
  font-weight: 600;
  padding-right: 0;
  padding-left: 12px;
}
.filterWrap {
  display: flex;
}
.trendsFilterSec {
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 1.6rem 12px;
  align-items: flex-start;
}
.summarySec {
}
.summary {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.summaryIconSec {
  width: 24px;
  margin-right: 8px;
}
.summaryIcon {
}
.summaryValue {
  font-weight: 600;
  margin-bottom: 2px;
}
.summaryLabel {
  color: #808080;
  font-size: 12px;
}
.trendsChart {
  padding: 0 1.6rem 2rem;
  max-width: 580px;
}
.tableSec {
}
.tableWrap table {
  min-width: 600px;
}
.tableTtl {
  font-size: var(--h5);
  font-weight: 600;
  margin-bottom: 2rem;
}

/* Schedule */
.scheduleSec {
  margin-bottom: 2rem;
  position: relative;
}
.scheduleTtlSec {
  margin-bottom: 4px;
}
.scheduleTtl {
  font-size: var(--h5);
  font-weight: 600;
}
div.calendarWrap {
  width: 100%;
  background: transparent;
  font-size: 15px;
}
div.calendarWrap [class*="rdrMonth"] {
  width: 100%;
}
div.calendarWrap [class*="rdrDayNumber"] span {
  color: var(--black);
  font-weight: 600;
}
div.calendarWrap [class*="rdrDayPassive"] span {
  color: #d5dce0;
  font-weight: 400;
}
div.calendarWrap [class*="rdrDayHovered"] [class*="rdrDayNumber"]::after,
div.calendarWrap [class*="rdrDayStartPreview"][class*="rdrDayEndPreview"] {
  content: unset !important;
  border: none !important;
}
div.calendarWrap [class*="rdrDayHovered"] [class*="rdrDayNumber"] span {
  transform: scale(1.5);
}
.visitList {
}
.visit {
  background: #f8f9f8;
  border: 0.85px solid #eef3ef;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 12px 12px 10px 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.visitClock {
  margin-right: 8px;
  margin-top: -3px;
}
.visitTxtSec {
  width: calc(100% - 44px);
}
.visitTtl {
  font-weight: 600;
  margin-bottom: 6px;
}
.visitTime {
  color: #808080;
}
.visitCaret {
  width: 12px;
  margin-left: 8px;
}
.emptySec {
  text-align: center;
  padding: 2rem 0;
  background: #fafafa;
  border-radius: 8px;
}
.emptySec svg {
  width: 70%;
  max-width: 400px;
  margin-bottom: 2rem;
}
.emptySec p {
  text-align: center;
}
.profileSec {
  display: none;
}
.scheduleTtlSec button {
  display: none;
}

@media screen and (max-width: 1200px) {
  .main {
    left: calc(180px + 1rem);
    width: calc(100% - 390px - 6rem);
  }
  .extra {
    padding-right: 0;
    left: calc((100% - 400px - 7rem) + 180px + 4rem);
    width: 250px;
  }
  .statList,
  .secWrap {
    padding: 0;
    background: transparent;
    border-radius: 0;
  }
  .statCard {
    border: 1px solid rgba(31, 29, 30, 0.05);
    border-radius: 8px;
    box-shadow: unset;
  }
  .tableTtl,
  .scheduleTtl {
    font-size: 20px;
  }
  .tableWrap {
    /* overflow-x: scroll; */
  }
  .table {
    padding: 1px 0px;
    overflow: auto;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    flex-direction: column;
  }
  .main {
    width: 100%;
    position: unset;
    height: auto;
    padding-top: 1rem;
  }
  .extra {
    position: unset;
    overflow: unset;
  }
  .statList {
    overflow-x: scroll;
    display: flex;
    gap: unset;
    margin-bottom: 1.5rem;
  }
  .statCard {
    min-width: 140px;
    margin-right: 1rem;
    width: 25%;
  }
  .statCard:hover {
    border-color: #0fc94b;
  }
  .trendsHeading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0;
    border-width: 1px;
  }
  .trendsHeading p {
    font-size: 18px;
    margin-bottom: 1rem;
  }
  .filterWrap {
    padding-left: 2px;
  }
  .trendsFilterSec {
    padding: 1.2rem 0 0;
  }
  .summarySec {
    display: none;
  }
  .trendsChart {
    max-width: unset;
    padding: 0;
  }
  .tableTtl,
  .scheduleTtl {
    font-size: 18px;
  }

  .extra {
    width: 100%;
    height: unset;
  }
  .scheduleTtlSec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .scheduleTtlSec button {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    padding: 0;
  }
  .scheduleTtlSec button svg {
    margin-right: 6px;
  }
  .scheduleTtlSec button svg path {
    fill: var(--green);
  }
  div.calendarWrap {
    position: absolute;
    background: var(--white);
    z-index: 10;
    box-shadow: 0px 0px 4px 2px rgb(92 88 88 / 15%);
    max-width: 350px;
    right: 0px;
    border-radius: 6px;
    display: none;
  }
  div.showMobileCalendar {
    display: block;
  }
}

@media screen and (max-width: 800px) {
  .main {
    padding-top: 2rem;
  }
  .profileSec {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  .profileSec img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 1rem;
  }
  .profileSec p {
    font-weight: 600;
    font-size: 16px;
  }
}
