.footerBg {
  background: var(--black);
}
.footer {
  padding-top: 5rem;
  padding-bottom: 1.5rem;
  color: var(--white);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 12px;
}
.sec1 {
  width: 48%;
}
.logoSec {
  border-bottom: 1px solid var(--white);
  padding-left: 12px;
  padding-bottom: 22px;
  margin-bottom: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
}
.logo svg {
  width: 32px;
}
.logo p {
  font-size: 22px;
  letter-spacing: 0.5px;
}
.listSec {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 1.9;
  font-weight: 500;
}
.listSec ul {
  list-style-type: none;
}
.listSec ul li {
}
.footer a {
  color: var(--white);
  text-decoration: none;
}
.sec2 {
  width: 32%;
}
.backToTop {
  margin-left: auto;
  display: flex;
  align-items: center;
  color: var(--white);
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 32px;
  font-weight: 500;
  -webkit-text-stroke-width: 0.2px;
  -webkit-text-stroke-color: var(--white);
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}
.backToTop svg {
  transform: rotate(-45deg);
  width: 24px;
  margin-left: 2rem;
}
.socialsNews {
  border-top: 1px solid var(--white);
  padding-top: 2.5rem;
}
.socials {
  list-style-type: none;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  font-weight: 500;
  margin-bottom: 3.2rem;
}
.socials li {
}
.news {
}
.newsTtl {
  color: var(--green);
  margin-bottom: 0.8rem;
}
.newsTxt {
  opacity: 0.5;
  font-weight: 300;
  letter-spacing: 0.3px;
  max-width: 350px;
  line-height: 1.7;
  margin-bottom: 1rem;
}
.form {
  position: relative;
  max-width: 240px;
}
.form input {
  border: 2px solid rgba(250, 250, 250, 0.5);
  border-radius: 90px;
  background: transparent;
  color: var(--white);
  width: 100%;
  height: 44px;
  padding-left: 14px;
  font-size: 12px;
}
.form button {
  background: var(--green);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
}
.form button svg {
  width: 50%;
}
.sec3 {
  width: 100%;
  color: rgba(250, 250, 250, 0.6);
  font-size: 12px;
  line-height: 2;
}
.txt {
}
.dvdr {
  margin: 14px 0 8px;
  height: 1px;
  background: var(--white);
  border: none;
}
.rights {
}
.backToTopMobile {
  display: none;
}
@media screen and (max-width: 1000px) {
  .sec1 {
    width: 55%;
  }
  .sec2 {
    width: 35%;
  }
}

@media screen and (max-width: 800px) {
  .sec1 {
    width: 100%;
    margin-bottom: 3rem;
  }
  .sec2 {
    width: 100%;
  }
  .logo svg {
    width: 22px;
  }
  .logo p {
    font-size: 16px;
  }
  .footer {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  .listSec {
    line-height: 2.2;
  }
  .backToTop {
    display: none;
  }
  .backToTopMobile {
    display: block;
    margin-left: auto;
    display: flex;
    align-items: center;
    color: var(--white);
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 18px;
  }
  .backToTopMobile svg {
    transform: rotate(-45deg);
    width: 20px;
    width: 12px;
    margin-top: -16px;
    margin-left: 4px;
  }
  .socialsNews {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .socials {
    display: flex;
    flex-direction: column;
    line-height: 2.2;
  }
  .sec2 {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .footer {
    padding-top: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 12px;
  }
  .listSec {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    line-height: 2.5;
  }
  .listSec ul {
    margin-bottom: 1.5rem;
  }
  .backToTopMobile {
    font-size: 14px;
  }
  .logoSec {
    padding-bottom: 10px;
    padding-left: 8px;
    margin-bottom: 2rem;
  }
  .sec1 {
    margin-bottom: 1rem;
  }
  .sec2 {
    font-size: 14px;
    line-height: 2.5;
    margin-bottom: 0;
  }
  .socialsNews {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    padding-top: 0;
  }
  .socials {
    flex-direction: row;
    width: 300px;
    margin-top: 1.5rem;
  }
  .form {
    margin: auto;
    max-width: 300px;
  }
  .newsTxt {
    max-width: 300px;
  }
  .newsTtl {
    margin-bottom: 0;
  }
  .sec3 {
    text-align: center;
  }
  .dvdr {
    margin-top: 0;
  }
}
