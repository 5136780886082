.content {
  padding: 1.5rem;
}
.heading {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ttl {
  font-size: 24px;
}
.form {
  display: grid;
  margin-bottom: 1.5rem;
}
.btns {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
}
.btns button {
  font-size: 14px;
}
