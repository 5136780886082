.agents {
  padding-top: 5rem;
  padding-bottom: 13rem;
}
.searchWrap {
  grid-area: a;
  display: flex;
  align-items: center;
}
.searchWrap > svg {
  margin-right: 1.5rem;
}
.searchWrap > div {
  position: relative;
  width: 100%;
}
.searchWrap div svg {
  position: absolute;
  width: 20px;
  left: 16px;
  top: 15px;
}
.searchWrap input {
  height: 48px;
  background: transparent;
  border: 1px solid #808080;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  padding-left: 3rem;
  font-size: 15px;
  padding-right: 1rem;
}
.agentsWrap {
  margin-top: 2.5rem;
}
.agentsList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem;
}
.topDealsWrap {
}
.topDealsWrap h2 {
  font-weight: 600;
  font-size: var(--h4);
  margin-bottom: 2rem;
}
.topDealsWrap > div {
  margin-bottom: 2rem;
}
.topDealsWrap [class*="imgSec"] {
  height: 320px;
}
.topDealsWrap [class*="amtSec"] button {
  font-size: 11px;
  border-radius: 6px;
  padding: 8px 14px;
}
.agentCard {
  background: #f4f4f4;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 140px auto;
  gap: 1rem;
  padding: 1rem 1.2rem;
  /* margin-bottom: 1.5rem; */
}
.agentCard > div {
  background: #fafafa;
  border-radius: inherit;
  padding: 14px;
  /* height: fit-content; */
}
.personal {
  text-align: center;
}
.personal img {
  width: 100px;
  min-width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 1rem;
  border-radius: 50%;
}
.name {
  color: var(--green);
  font-size: var(--h5);
  font-weight: 600;
  margin-bottom: 12px;
  text-transform: capitalize;
}
.role {
  color: #808080;
}
.moreInfo {
  display: grid;
  gap: 1rem;
}
.moreInfo p {
  line-height: 1.7;
  /* margin-bottom: 1.5rem; */
}
.contact {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  max-width: 450px;
  color: #808080;
}
.contact > div {
  display: flex;
  align-items: center;
}
.contact svg {
  margin-right: 14px;
}
.contact span {
}
.contact a {
  color: inherit;
  text-decoration: none;
}
.btn {
  font-weight: 400;
  /* margin-top: 1rem; */
  padding: 0;
  display: flex;
  justify-content: start;
  align-items: center;
}
.btn svg {
  margin-left: 1rem;
  width: 12px;
}
.btn svg path {
  fill: var(--green);
}

.empty {
  text-align: center;
}
.empty svg {
  width: 80%;
  max-width: 400px;
  margin-top: 4rem;
  margin-bottom: 2rem;
}
.empty p {
  font-size: 16px;
  font-weight: 500;
}

@media screen and (max-width: 1100px) {
  .agentsWrap {
    gap: 2rem;
  }
}

@media screen and (max-width: 850px) {
  .agentsWrap {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .agentsList {
    max-width: 800px;
  }
  .topDealsWrap {
    display: none;
  }
  .agents {
    padding-bottom: 4rem;
    padding-top: 3rem;
  }
  .searchWrap input {
    height: 40px;
    border-radius: 7px;
    font-size: 14px;
    padding-left: 2.4rem;
  }
  .searchWrap > div svg {
    width: 14px;
    left: 12px;
    top: 14px;
  }
  .searchWrap > svg {
    margin-right: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .agentsList {
    grid-template-columns: 1fr;
  }
  .agentCard {
    grid-template-columns: 1fr;
  }
}
