.heading {
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 4rem;
}
.ttl {
  color: var(--black);
  font-size: 22px;
  font-weight: 600;
  position: relative;
  padding-left: 2rem;
}
.ttl::before {
  content: "";
  background: var(--green);
  width: 20px;
  height: 40px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}
.addBtn {
  font-size: 17px;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  height: 44px;
  font-family: "Source Sans Pro";
  font-weight: 500;
}
.addBtn svg {
  margin-right: 12px;
}

.searchWrap {
  margin-bottom: 1.2rem;
  display: flex;
  align-items: center;
  max-width: 420px;
}
.searchWrap > svg {
  width: 16px;
  margin-right: 1rem;
}
.searchWrap input {
  background: transparent;
  border: none;
  width: calc(100% - 30px);
  font-size: 15px;
}
section.tableWrap {
  background: transparent;
  padding: 0;
}
.emptySec {
  text-align: center;
  padding: 2rem 0;
  background: #fafafa;
  border-radius: 8px;
}
.emptySec > svg {
  width: 70%;
  max-width: 450px;
  margin-bottom: 3rem;
}
.emptySec p {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}
.center {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}
@media screen and (max-width: 1200px) {
  .tableWrap {
    padding: 2px !important;
    overflow-x: scroll;
  }
  .tableWrap table {
    min-width: 700px;
  }
  .tableTtl {
    font-size: 20px;
  }
}

@media screen and (max-width: 800px) {
  .ttl {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 1.5rem;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 20px;
  }
  .ttl::before {
    width: 10px;
  }
}

@media screen and (max-width: 600px) {
  .addBtn {
    font-size: 13px;
    padding: 8px 12px;
    height: 40px;
  }
  .emptySec p {
    font-size: 16px;
  }
  .searchWrap p {
    font-size: 14px;
  }
}
